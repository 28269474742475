@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-icon {
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  position: relative;
  z-index: 9999 !important;
}

.react-datepicker-popper {
  z-index: 50 !important;
}

.date-picker-rounded {
  display: block;
  border-radius: 50px;
  padding: 6px;
  width: 100%;
  border: 0.1px solid #d1d5db !important;
  margin-top: 4px;
}

.date-picker {
  display: block;
  border-radius: 5px;
  padding: 6px;
  width: 100%;
  border: 0.1px solid #d1d5db !important;
  margin-top: 4px;
}

.calendar {
  display: block;
  border-radius: 50px;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  background-color: #6c757d;
  color: white;
  font-size: 15px;
}

.calendar::placeholder,
.calendar-rounded::placeholder {
  text-align: left;
  color: #fff;
  opacity: 0.8;
}

.date-picker::placeholder,
.date-picker-rounded::placeholder {
  text-align: left;
  color: #9ca3af;
  font-size: 14px;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  display: block;
  border-radius: 5px;
  padding: 6px;
  width: 100%;
  border: 1px solid #d1d5db !important;
}

@layer base {
  .standard-border {
    @apply border-2 border-gray-200 rounded-3xl;
  }
}
